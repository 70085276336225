<template>
  <div class="index-page">
    <h2>{{ $t("home") }}</h2>
    <NuxtLink :to="localePath('/store')">store</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/components/clientonly')">components/ClintOnly</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/components/nuxterrorboundary')">components/NuxtErrorBoundary</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/components/nuxtimg')">components/NuxtImg</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/components/teleport')">components/Teleport</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables')">composables</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/usestate/')">composables/useState</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/usecookie/')">composables/useCookie</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/usefetch')">composables/useFetch</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/useasyncdata')">composables/useAsyncData</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/useerror')">composables/useError</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/usehead')">composables/useHead</NuxtLink>
    <br>
    <NuxtLink :to="localePath('/composables/usehydration')">composables/useHydration</NuxtLink>
    <br>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath();

// throw createError({
//     statusCode: 404,
//     statusMessage: 'Page Not Found'
//   })



</script>
<style lang="scss" scoped></style>
